<!--  -->
<template>
  <div class="eg_contr">
    <van-tabs
      title-active-color="#FF6600"
      title-inactive-color="#666666"
      color="#FF6600"
      v-model="active"
      @change="onChange"
    >
      <van-tab title="平台介绍" name="1">
        <div class="rg_huw">
          <img
            src="../../image/jieshaoshuoming_banner@2x.png"
            class="eg_img"
            alt=""
          />
          <div class="r_huwf">
            <div class="h_jwef">
              <div class="rh_vin">
                <img src="../../image/xing@2x.png" alt="" />
                <div class="eg_jhu">平台软件证书</div>
                <img src="../../image/xing2@2x.png" alt="" />
              </div>
              <div class="name_cs">著作权人：四川车世际科技有限公司</div>
              <div class="name_cs">
                可在华为、小米、OPPO、VIVO应用市场下载本APP
              </div>
              <div class="e_hueg">
                <img
                  class="eg_huwf"
                  src="../../image/ruanjianzhengshu@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div class="we_uwe">
              <div>
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">平台概述</div>
                </div>
                <div>
                  <div class="eg_hugwef">
                    “车世际”是一款服务于车辆后服务市场的信息服务平台。
                  </div>
                  <div class="eg_hugwef">
                    主要业务：车辆出租、车辆保养、二手车交易、新车交易
                  </div>
                  <div class="eg_hugwef">
                    通过承载服务信息的方式，利用互联网的快速沟通的特点，打破信息差，让商家多做生意，让消费者快速找到合适的车辆保养店并省钱。同时围绕车辆服务，未车主提供，二手车信息发布买卖等服务。通过实名店家认证，多重审核，支持平台的信息服务质量，是一个值得信赖的汽车后市场信息服务平台。
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">平台定位</div>
                </div>
                <div>
                  <div class="eg_hugwef">
                    作为汽车后服务市场的主力军，平台在建立之初，经过多方调研和自身实力。把平台的定位确定为以3、4线小城市为企业发展之根、已本地服务为主要特点，已信息化为载体的发展模式，从而打造车世际APP信息服务平台。
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">理想与愿景</div>
                </div>
                <div>
                  <div class="eg_hugwef">
                    作为科技企业，我们一直前行，不忘理想。我们的理想是用科技服务打破信息壁垒，重新定义车辆后服务市场。<br />
                    我们的愿景：人与车&科技改变生活
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">价值观</div>
                </div>
                <div>
                  <div class="eg_hugwef">
                    我们的价值观是争做汽车后服务市场的科学信息化服务与实践的先行者。
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">服务对象</div>
                </div>
                <div>
                  <div class="eg_hugwef">
                    商家：汽车零售商、汽车批发商、二手汽车零售商、汽车维修商、汽车美容商。
                  </div>
                  <div class="eg_hugwef">
                    普通用户：有车用户需求保养产品、有车用户需要买卖车辆、有车用户需要托管服务、无车用户需要买卖车辆、无车用户需要开网约车的司机。
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">联系电话</div>
                </div>
                <div>
                  <div class="eg_hugwef">客服电话：028-85984189</div>
                  <div class="eg_hugwef">业务合作：028-85984189</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="功能流程说明" name="2">
        <div class="rg_huw">
          <img
            src="../../image/jieshaoshuoming_banner@2x.png"
            class="eg_img"
            alt=""
          />
          <div class="r_huwf">
            <div class="we_uwe">
              <div>
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">车辆业务板块</div>
                </div>
                <div  class="eg_huggtfqd">
                  <div class="eg_hyftw" @click="onClick(1)">
                    <div class="eg_hgw">1.商家注册步骤?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(2)">
                    <div class="eg_hgw">2.车辆上架（卖车）流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(3)">
                    <div class="eg_hgw">3.车辆交易（买车）流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(4)">
                    <div class="eg_hgw">4.车辆租赁流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                    <div class="eg_hyftw" @click="onClick(20)">
                    <div class="eg_hgw">5.车辆短期无押金租聘(乘客端)</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(5)">
                    <div class="eg_hgw">6.商机客户管理?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(6)">
                    <div class="eg_hgw">7.商家好友管理?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(7)">
                    <div class="eg_hgw">8.核销手机管理?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">保养业务板块</div>
                </div>
                <div  class="eg_huggtfqd">
                  <div class="eg_hyftw" @click="onClick(8)">
                    <div class="eg_hgw">1.保养商品上架流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(9)">
                    <div class="eg_hgw">2.保养商品购买流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(10)">
                    <div class="eg_hgw">3.优惠劵制作与发布?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(11)">
                    <div class="eg_hgw">4.托管业务办理流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">增值业务板块</div>
                </div>
                <div  class="eg_huggtfqd">
                  <div class="eg_hyftw" @click="onClick(12)">
                    <div class="eg_hgw">1.停驶流程?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(13)">
                    <div class="eg_hgw">2.停驶无忧介绍?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(14)">
                    <div class="eg_hgw">3.积分商城介绍?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                </div>
              </div>
              <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">个人功能板块</div>
                </div>
                <div  class="eg_huggtfqd">
                  <div class="eg_hyftw" @click="onClick(15)">
                    <div class="eg_hgw">1.个人资料编辑?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(16)">
                    <div class="eg_hgw">2.银行卡管理?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(17)">
                    <div class="eg_hgw">3.托管收益介绍?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                  <div class="eg_hyftw" @click="onClick(18)">
                    <div class="eg_hgw">4.金币介绍?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                </div>
              </div>
                <div style="padding-top: 18px">
                <div class="erg_jyg">
                  <div class="e_iygd"></div>
                  <div class="titl_name">支付功能模块</div>
                </div>
                <div  class="eg_huggtfqd">
                  <div class="eg_hyftw" @click="onClick(19)">
                    <div class="eg_hgw">1.如何领取及使用优惠券?</div>
                    <van-icon color="#999999" name="arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      active: "0",
      index: 0,
      Url: "https://cheshiji.com.cn/",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onClick(e) {
      this.index = e;
      if (e == 1) {
        this.$router.push({
          path: "/Introduction/processOne",
        });
      } else if (e == 2) {
        this.$router.push({
          path: "/Introduction/processTwo",
        });
      } else if (e == 3) {
        this.$router.push({
          path: "/Introduction/processThree",
        });
      } else if (e == 4) {
        this.$router.push({
          path: "/Introduction/processFour",
        });
      } else if (e == 5) {
        this.$router.push({
          path: "/Introduction/processFive",
        });
      } else if (e == 6) {
        this.$router.push({
          path: "/Introduction/processSix",
        });
      } else if (e == 7) {
        this.$router.push({
          path: "/Introduction/processSeven",
        });
      } else if (e == 8) {
        this.$router.push({
          path: "/Introduction/processEight",
        });
      } else if (e == 9) {
        this.$router.push({
          path: "/Introduction/processNine",
        });
      } else if (e == 10) {
        this.$router.push({
          path: "/Introduction/processTen",
        });
      } else if (e == 11) {
        this.$router.push({
          path: "/Introduction/processEleven",
        });
      } else if (e == 12) {
        this.$router.push({
          path: "/Introduction/processTwelve",
        });
      } else if (e == 13) {
        this.$router.push({
          path: "/Introduction/processThirteen",
        });
      } else if (e == 14) {
        this.$router.push({
          path: "/Introduction/processFourteen",
        });
      } else if (e == 15) {
        this.$router.push({
          path: "/Introduction/processFifteen",
        });
      } else if (e == 16) {
        this.$router.push({
          path: "/Introduction/processSixteen",
        });
      } else if (e == 17) {
        this.$router.push({
          path: "/Introduction/processSeventeen",
        });
      } else if (e == 18) {
        this.$router.push({
          path: "/Introduction/processEighteen",
        });
      }else if(e==19){
        this.$router.push({
          path:'/Introduction/processNineteen'
        })
      }else if(e==20){
        this.$router.push({
          path:'/Introduction/processTwenty'
        })
      }
    },
    onChange(e) {
      this.index = 0;
      this.active = e;
      window.sessionStorage.setItem("active", e);
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    if (window.sessionStorage.getItem("active")) {
      let active = window.sessionStorage.getItem("active");
      this.active = active;
    }
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
* {
  margin: 0;
  padding: 0;
}
.eg_img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.rg_huw {
  width: 100%;
  position: relative;
  padding-top: 210px;
  box-sizing: border-box;
  background: #efefef;
}
.r_huwf {
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
}
.h_jwef {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
}
.rh_vin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 9px;
}
.eg_jhu {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  line-height: 27px;
  color: #ff6600;
  padding: 0 5px;
  box-sizing: border-box;
}
.name_cs {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  text-align: center;
}
.e_hueg {
  padding: 9px 20px 17px 20px;
  box-sizing: border-box;
}
.eg_huwf {
  width: 100%;
}
.we_uwe {
  width: 100%;
  background: #ffffff;
  margin-top: 10px;
  border-radius: 12px 12px 0px 0px;
  padding: 18px 12px;
  box-sizing: border-box;
}
.e_iygd {
  width: 3px;
  height: 16px;
  background: #ff6600;
}
.titl_name {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
  padding-left: 5px;
}
.erg_jyg {
  display: flex;
  align-items: center;
}
.eg_hugwef {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  padding-top: 7px;
}
.eg_hgw {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
  /* text-indent: 1em; */
}
.eg_hgw_active {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 25px;
  color: #ff6600;
}
.eg_hyftw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 8px;
  padding-top: 8px;
}
.eg_huggtfqd{
  padding-left: 12px;
}
</style>